

  *{
    margin: 0px;
    padding: 0px;
}

.logoContainer{
   align-content: center;
   display: grid;
   justify-items: center;
}

.Nav {
    
    background-color:#171f3f ;    
    border-right-width: 1px;
    border-right-style: solid;
    width:200px; 
    padding-Top:20;
    height:100%;
    
    
    
}


.logoVart {
    height: 50px;
    width: 200px;
    margin-top: 20px;
    
}



.logout {
    display: grid;
   justify-items: center;
   margin-top: 600px;
}

.titulos {
    display: flex;
    font-size: 14px;
    
    
   
}

.icons {
   
    margin-right: 10px;
    margin-left: -10px;
}