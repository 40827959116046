body {
    margin: 0;
    padding: 0;
    height: 100vh;
    
    
   
  }

  .InicioSesion {

    background-color: #101436;
    border-style: solid;
    height: 400px;
    padding-left: 20px;
    padding-right: 20px;
    width: 300px;
    border-radius: 20px;
    margin-left: 500px;
    margin-top: 250px;
    
    }

    .logoElipse {
      height: 500px;
      width: 500px;
      margin-bottom: -900px;
    }
    .inputInicio {
         border-radius: 5px;
         width: 250px;
         height: 30px;
         margin-bottom: 10px;
         background-color: #5b6d84;
         border-color: #5b6d84;
    }

::placeholder {
    color:white;
    opacity: 1;
    
    
}


.parent{
  display: grid;
  justify-items: center;
}

.vart {
  margin-top: 20px;
  width: 150px;
  height: 50px;
  margin-bottom: 10px;
  object-fit:contain;
  
}






