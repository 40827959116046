html,body{
  margin:0;
  padding:0;
  background-color: #03062A;
  color:#FAFAFA;
  overflow: hidden;

}
a{
  color: #FAFAFA;
}

#sidebar{
  background-color: #171F3F;
  width: 259px;
  min-height: 100vh;
}
.logo{
  max-width: 70%;
  margin:0 auto;
}

.content{
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
}

input,select{
  color-scheme: dark;
}
input[role='combobox']{
  color:white!important
}

td,
th {
  padding: 8px;
  text-align: left;
}

td {
  border-bottom: 1px solid rgba(85, 106, 126, .5);
}

th {
  border-bottom: 1px solid #556A7E;
}


@media print {
  html,body{
    background-color: white;
    background: white;
    overflow: visible;
  }
  input,select{
    color-scheme: light;
  }
}
