@media only screen and (min-width: 1024px) {
    .InicioSesion {

         background-color: #101436;
         border-style: solid;
         height: 400px;
        padding-left: 20px;
         padding-right: 20px;
         width: 300px;
         border-radius: 20px;
         margin-left: 1400px;
         margin-top: 250px;
         
         }

         .logoElipse {
            height: 1200px;
            width: 1200px;
            margin-top: -100px;
           margin-bottom: -1000px;
          }
}

