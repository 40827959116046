@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Custom-1";
    src: url("../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
  }

  .css-13cymwt-control{
    background-color:rgb(47 56 85/40)!important
  }

  